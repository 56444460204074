<template>
  <div
    :class="{
      'size-6': !isButton,
    }"
  >
    <FimButton
      :type="isButton ? 'secondary' : 'raw'"
      data-testid="pdp_button_favorite"
      no-padding
      class="!gap-0"
      :class="[
        {
          'p-4': isButton,
          'pointer-events-none opacity-30': isUpdating,
        },
      ]"
      @click="toggleWishlistItem"
    >
      <div class="sr-only">
        {{
          isInWishlist
            ? $t('basket_card.remove_from_wishlist_label')
            : $t('basket_card.add_to_wishlist_label')
        }}
      </div>
      <template #icon>
        <div :key="keyId">
          <LazyIconFielmannFavoriteInactive
            v-if="!isInWishlist"
            data-testid="list_button_favorite_inactive"
            class="size-6"
          />
          <LazyIconFielmannClose
            v-else-if="isInWishlist && showCloseIcon"
            data-testid="list_button_favorite_active"
            class="size-6"
          />
          <LazyIconFielmannFavoriteActive
            v-else
            data-testid="list_button_favorite_active"
            class="size-6"
          />
        </div>
      </template>
    </FimButton>
  </div>
</template>

<script setup lang="ts">
import type { Product, Variant } from '@scayle/storefront-nuxt'

const props = defineProps({
  product: {
    type: Object as PropType<Product>,
    required: true,
  },
  variant: {
    type: Object as PropType<Variant>,
    default: undefined,
    required: false,
  },
  showCloseIcon: {
    type: Boolean,
    default: false,
  },
  layout: {
    type: String,
    default: 'default',
    validator: (val: string) => ['default', 'button'].includes(val),
  },
})

const isButton = props.layout === 'button'
const { product, variant } = toRefs(props)

const { isUpdating, isInWishlist, toggleWishlistItem } = useFimWishlist({
  product: product,
  variant: variant,
})

const keyId = ref(0)
onMounted(() => {
  watch(
    isInWishlist,
    (value) => {
      keyId.value++
    },
    { immediate: true },
  )
})
</script>
